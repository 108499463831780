import React, { useState } from 'react';
import { Form, Container, Row, Col, FormGroup, Input, Label, Button } from 'reactstrap';
import ImageUploader from 'react-images-upload';
import PaystackButton from 'react-paystack';


function Home(props) {
  const key = "pk_live_1f96f28740fb4d83a5ebf6cc068609ec74cbe2d9";
  let [userData, setUserData] = useState([]);
  const [uploadFile, setFileUpload] = useState({ idCardImage: '', photograph: '', utilityBill: '' });
  let [amtToPay, setamtToPay] = useState(0);
  let [tenor, setTenor] = useState(30);
  const [showTermsAndPay, setToPay] = useState(false);
  const [showAmtReqField, setAmtReqField] = useState(true);

  const fd = new FormData();

  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  }

  const handleAmouuntChange = (e) => {
    let amtToPay = Math.floor(0.07 * e.target.value);

    if (amtToPay < 100000) {
      amtToPay = 100000;
    }
    setamtToPay(amtToPay);
    setUserData({
      ...userData,
      amount: e.target.value, amtToPay
    })
  }

  const handleTenorChange = (e) => {
    setTenor(e.target.value);
    setUserData({
      ...userData,
      tenor: e.target.value
    })
  }

  const handleTravelPurposeChange = (e) => {
    // if (e.target.value === 'Short Course' || e.target.value === 'Schooling') {
    //   setAmtReqField(false)
    // } else {
    //   setAmtReqField(true)
    // }
    setUserData({
      ...userData,
      travelpurpose: e.target.value
    })
  }

  const onIdChange = (file) => {
    // console.log(file)
    setFileUpload({
      ...uploadFile,
      idCardImage: file[0]
    });
  };

  const onPhotoChange = (file) => {
    // console.log(file[0])
    setFileUpload({
      ...uploadFile,
      photograph: file[0]
    });
  };

  const onUtilityBillChange = (file) => {
    setFileUpload({
      ...uploadFile,
      utilityBill: file[0]
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!uploadFile.idCardImage || !uploadFile.photograph || !uploadFile.utilityBill) {
      alert('Upload photograph, ID and utility Bill');
    } else {
      const entries = Object.entries(userData);
      for (const [key, value] of entries) {
        fd.append(key, value);
      }

      if (uploadFile) {
        fd.append('idCardImage', uploadFile.idCardImage)
        fd.append('photograph', uploadFile.photograph)
        fd.append('utilityBill', uploadFile.utilityBill)
      }

      // for (var pair of fd.entries()) {
      //   console.log(pair[0] + ' - ' + pair[1]);
      // }
      setToPay(true);
      // window.scrollTo(0, document.body.scrollHeight);
    }
  }

  // const handleUpload = () => {

  //   const entries = Object.entries(userData);
  //   for (const [key, value] of entries) {
  //     fd.append(key, value);
  //   }

  //   if (uploadFile) {
  //     fd.append('idCardImage', uploadFile.idCardImage)
  //     fd.append('photograph', uploadFile.photograph)
  //     fd.append('utilityBill', uploadFile.utilityBill)
  //   }

  //   for (var pair of fd.entries()) {
  //     console.log(pair[0] + ' - ' + pair[1]);
  //   }

  //   fetch("https://register.arsured.com/api/register.php", {
  //     body: fd,
  //     method: "post",
  //     headers: {
  //       'Accept': 'application/json',
  //     },
  //   })
  //     .then(res => res.json())
  //     .then(data => {
  //       console.log(data);
  //     })
  // }

  const close = () => {

  }

  const callback = (response) => {
    console.log(response)
    if (response.message === "Approved" && response.status === "success") {

      fetch("https://register.arsured.com/api/register.php", {
        body: fd,
        method: "post",
        headers: {
          'Accept': 'application/json',
        },
      })
        .then(res => res.json())
        .then(data => {
          // console.log(data)
          if (data == '1') {
            alert('Application Submitted Successfully');
            setUserData([]);
            setamtToPay(0);
            setToPay(false);
          } else {
            alert('Error occured, please try again');
          }
          // document.getElementById("submitBtn").disabled = false;
        })
    } else {
      alert('Error occured, please try again');
    }
  }

  const getReference = () => {
    //you can put any unique reference implementation code here
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";

    for (let i = 0; i < 15; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }

  return (
    <div className="Register">
      <Container style={{ padding: '0 70px', fontSize: '12px' }}>
        <a href="https://www.arsured.com" className="btn btn-danger btn-sm mt-2">Back to Home</a>
        <h4 className="text-center" style={{ textDecoration: 'underline' }}>Arsured Global Registration form</h4>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs="12" sm="6" md="4">
              <FormGroup>
                <Label>Surname</Label>
                <Input onChange={handleChange} name="surname" placeholder="Surname" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Firstname</Label>
                <Input onChange={handleChange} name="firstname" placeholder="Firstname" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Middlename</Label>
                <Input onChange={handleChange} name="middlename" placeholder="Middlename" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Lastname</Label>
                <Input onChange={handleChange} name="lastname" placeholder="Lastname" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Phone</Label>
                <Input onChange={handleChange} name="phone" placeholder="Phone" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Email</Label>
                <Input onChange={handleChange} type="email" name="email" placeholder="Email" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Mother's Maiden Name</Label>
                <Input onChange={handleChange} name="maidenname" placeholder="Mother's Maiden Name" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Residential Address</Label>
                <Input onChange={handleChange} name="addr" placeholder="Residential Address" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>State Of Residence</Label>
                <Input onChange={handleChange} name="state" placeholder="State Of Residence" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>LGA Of Residence</Label>
                <Input onChange={handleChange} name="residentlga" placeholder="L.G.A Of Residential" required={false} />
              </FormGroup>
              {showAmtReqField &&
                <FormGroup>
                  <Label>Amount Requested</Label>
                  <Input type="number" onChange={handleAmouuntChange} name="amount" placeholder="Amount Requested" required={false} />
                </FormGroup>
              }
            </Col>
            <Col xs="12" sm="6" md="4">
              <FormGroup>
                <Label>Nationality</Label>
                <Input onChange={handleChange} name="nationality" placeholder="Nationality" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Gender</Label>
                <select onChange={handleChange} className="form-control" defaultValue="Gender" name="gender" required={false}>
                  <option disabled>Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </FormGroup>
              <FormGroup>
                <Label>Marital Status</Label>
                <select onChange={handleChange} className="form-control" defaultValue="Marital Status" name="maritalstatus" required={false}>
                  <option disabled>Marital Status</option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widow">Widow</option>
                  <option value="Widower">Widower</option>
                </select>
              </FormGroup>
              <FormGroup>
                <Label>Date Of Birth</Label>
                <Input onChange={handleChange} type="date" name="dob" placeholder="Date Of Birth" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>BVN</Label>
                <Input onChange={handleChange} type="number" name="bvn" placeholder="BVN" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Profession</Label>
                <Input onChange={handleChange} name="profession" placeholder="Profession" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Business Address</Label>
                <Input onChange={handleChange} name="businessaddr" placeholder="Business Address" />
              </FormGroup>
              <FormGroup>
                <Label>Employer's Name</Label>
                <Input onChange={handleChange} name="employername" placeholder="Employer's Name" />
              </FormGroup>
              <FormGroup>
                <Label>Next Of Kin</Label>
                <Input onChange={handleChange} name="nextofkin" placeholder="Next Of Kin" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Next Of Kin Profession</Label>
                <Input onChange={handleChange} name="profkin" placeholder="Profession Of Next Of Kin" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Travel Purpose</Label>
                <select onChange={handleTravelPurposeChange} className="form-control" defaultValue="Travel Purpose" name="travelpurpose" required={false}>
                  <option disabled>Travel Purpose</option>
                  <option value="Schooling">Schooling</option>
                  <option value="Short Course">Short Course/Conferences</option>
                  <option value="Residential">Residential</option>
                  <option value="Tourism">Tourism</option>
                </select>
              </FormGroup>
            </Col>
            <Col xs="12" sm="6" md="4">
              <FormGroup>
                <Label>Kin Relationship</Label>
                <Input onChange={handleChange} name="kinrelation" placeholder="Relationship" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Kin Phone</Label>
                <Input onChange={handleChange} name="kinphone" placeholder="Next Of Kin Phone" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Kin Date Of Birth</Label>
                <Input onChange={handleChange} type="date" name="kindob" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Address Of Next Of Kin</Label>
                <Input onChange={handleChange} name="kinaddr" placeholder="Next Of Kin Address" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>State of Next Of Kin</Label>
                <Input onChange={handleChange} name="kinstate" placeholder="Next Of Kin State" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>LGA Of Next Of Kin</Label>
                <Input onChange={handleChange} name="kinlga" placeholder="L.G.A" required={false} />
              </FormGroup>
              <FormGroup>
                <Label>Means Of Identification</Label>
                <select onChange={handleChange} className="form-control" defaultValue="Means Of Identification" name="iden" required={false}>
                  <option disabled>Means Of Identification</option>
                  <option value="International Passport">International Passport</option>
                  <option value="National ID">National ID</option>
                  <option value="Driver's Licence">Driver's Licence</option>
                  <option value="Voter's Carc">Voter's Card</option>
                </select>
              </FormGroup>
              <FormGroup>
                <Label>ID Number</Label>
                <Input onChange={handleChange} name="idnumber" placeholder="ID Number" />
              </FormGroup>
              <FormGroup>
                <Label>ID Issue Date</Label>
                <Input onChange={handleChange} type="date" name="idissuedate" placeholder="ID Issue Date" />
              </FormGroup>
              <FormGroup>
                <Label>ID Expiry Date</Label>
                <Input onChange={handleChange} type="date" name="idexpirydate" placeholder="ID Expiry Date" />
              </FormGroup>
              <FormGroup>
                <Label>Tenor</Label>
                <select onChange={handleTenorChange} className="form-control" defaultValue="Tenor" name="accounttenor" required={false}>
                  <option disabled>Tenor</option>
                  <option value="30 Days">30 Days</option>
                  <option value="60 Days">60 Days</option>
                  <option value="90 Days">90 Days</option>
                  <option value="120 Days">120 Days</option>
                  <option value="180 Days">180 Days</option>
                  <option value="6 Months">6 Months</option>
                </select>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <h3>Uploads</h3>
            <Row>
              <Col xs="12" md="4">
                <ImageUploader
                  withIcon={true}
                  withPreview={true}
                  buttonText='Upload ID'
                  onChange={onIdChange}
                  imgExtension={['.jpg', '.jpeg', '.png']}
                  maxFileSize={2097152}
                  label="Max file size: 2MB, accepted: jpg|jpeg|png"
                />
              </Col>
              <Col xs="12" md="4">
                <ImageUploader
                  withIcon={true}
                  withPreview={true}
                  buttonText='Upload Passport Photograph'
                  onChange={onPhotoChange}
                  imgExtension={['.jpg', '.jpeg', '.png']}
                  maxFileSize={2097152}
                  label="Max file size: 2MB, accepted: jpg|jpeg|png"
                />
              </Col>
              <Col xs="12" md="4">
                <ImageUploader
                  withIcon={true}
                  withPreview={true}
                  buttonText='Upload Proof of Address e.g Utility bills'
                  onChange={onUtilityBillChange}
                  imgExtension={['.jpg', '.jpeg', '.png']}
                  maxFileSize={2097152}
                  label="Max file size: 2MB, accepted: jpg|jpeg|png"
                />
              </Col>
            </Row>
          </Row>
          {/* <Button onClick={handleUpload} size="lg" block color="primary">Upload Image</Button> */}
          {(showTermsAndPay === true) ? (
            <div style={{ marginTop: '-50px', position: 'absolute', background: '#929da9', padding: '10px', width: '100%', left: '0' }}>
              <h4>Terms and conditions</h4>
              <p>I hereby accept the terms and conditions as stated below to commence the process of funding my account for travel purposes.</p>
              <ul>
                <li>That ASSURED GLOBAL CONCEPT should open a savings account on my behalf in any of their partnering financial institutions or banks.</li>
                <li>That the account shall be funded with ₦{userData.amount} only.</li>
                <li>That the said fund will be booked in a fixed deposit for a tenor of {tenor}, subject to renewal.</li>
                <li>That the said fund with the interest belongs to ARSURED GLOBAL CONCEPT. It is offered to me as at short term loan.</li>
                <li>That the said principal sum, all accrued interests and other balances in the account shall be withdrawn by the bank upon completion of your tenor.</li>
                <li>That I shall make a non-refundable administrative fee and interest of 7% of the principal sum (₦{amtToPay}) only</li>
                <li>That I shall also pay the sum of ₦2,000 (Two Thousand) naira only as initial opening balance of the account.</li>
              </ul>
              <p>Thank you</p>
              <PaystackButton
                text="PAY NOW"
                class="payButton btn btn-primary btn-lg font-weight-bold btn-block"
                callback={callback}
                close={close}
                disabled={false}
                embed={false}
                reference={getReference()}
                email={userData.email}
                amount={amtToPay * 100}
                paystackkey={key}
                tag="button"
              />
            </div>
          ) :
            (
              <Row>
                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Amount : ₦{amtToPay}</span>
                <Button size="lg" block color="primary">Proceed to Payment</Button>
              </Row>
            )
          }
        </Form>
      </Container>
    </div>
  );
}

export default Home;