import React, {useState} from 'react';
import { Form, Container, Row, Col, FormGroup, Input, Label, Button } from 'reactstrap';
import ImageUploader from 'react-images-upload';

function Register(props) {
    let [files, setFile] = useState({
        photo:'',
        idCard:''
    })
    let [userData, setUserData] = useState([]);

    const handleChange = (event) => {
        setUserData({ ...userData, [event.target.name]: event.target.value });
    }

    const onIdChange = (file) => {
        console.log(file[0])
        setFile({
          ...files,
          photo: file[0]
        });
    };

    const onPhotoChange = (file) => {
        console.log(file[0])
        setFile({
          ...files,
          idCard: file[0]
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        
    }

  return (
    <div className="Register">
      <h1>Registration form</h1>
      <Container>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs="12" sm="6" md="4">
            	<FormGroup>
            		<Input onChange={handleChange} name="surname" placeholder="Surname" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="firstname" placeholder="Firstname" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="middlename" placeholder="Middlename" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="lastname" placeholder="Lastname" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="phone" placeholder="Phone" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} type="email" name="email" placeholder="Email" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="maidenname" placeholder="Mother's Maiden Name" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="addr" placeholder="Residential Address" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="state" placeholder="State Of Residence" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="residentlga" placeholder="L.G.A Of Residential" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="amount" placeholder="Amount Requested" required />
            	</FormGroup>
            </Col>
            <Col xs="12" sm="6" md="4">
            	<FormGroup>
            		<Input onChange={handleChange} name="nationality" placeholder="Nationality" required />
            	</FormGroup>
            	<FormGroup>
            		<select onChange={handleChange} className="form-control" defaultValue="Gender" name="gender" required>
            			<option disabled>Gender</option>
            			<option value="Male">Male</option>
            			<option value="Female">Female</option>
            		</select>
            	</FormGroup>
            	<FormGroup>
            		<select onChange={handleChange} className="form-control" defaultValue="Marital Status" name="maritalstatus" required>
            			<option disabled>Marital Status</option>
            			<option value="Single">Single</option>
            			<option value="Married">Married</option>
            			<option value="Divorced">Divorced</option>
            			<option value="Widow">Widow</option>
            			<option value="Widower">Widower</option>
            		</select>
            	</FormGroup>
            	<FormGroup>
            		<Row>
    	        		<Col xs="5"><Label>Date Of Birth</Label></Col>
    	        		<Col xs="7"><Input onChange={handleChange} type="date" name="dob" placeholder="Date Of Birth" required /></Col>
    	        	</Row>
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} type="number" name="bvn" placeholder="BVN" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="profession" placeholder="Profession" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="businessaddr" placeholder="Business Address" />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="employername" placeholder="Employer's Name" />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="nextofkin" placeholder="Next Of Kin" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="profkin" placeholder="Profession Of Next Of Kin" required />
            	</FormGroup>
            	<FormGroup>
            		<select onChange={handleChange} className="form-control" defaultValue="Travel Purpose" name="travelpurpose" required>
            			<option disabled>Travel Purpose</option>
            			<option value="Schooling">Schooling</option>
            			<option value="Residential">Residential</option>
            			<option value="Tourism">Tourism</option>
            		</select>
            	</FormGroup>
            </Col>
            <Col xs="12" sm="6" md="4">
            	<FormGroup>
            		<Input onChange={handleChange} name="kinrelation" placeholder="Relationship" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="kinphone" placeholder="Next Of Kin Phone" required />
            	</FormGroup>
            	<FormGroup>
            	<Row>
            		<Col xs="5">Kin Date Of Birth</Col>
            		<Col xs="7"><Input onChange={handleChange} type="date" name="kindob" required /></Col>
            	</Row>
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="kinaddr" placeholder="Next Of Kin Address" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="kinstate" placeholder="Next Of Kin State" required />
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="kinlga" placeholder="L.G.A" required />
            	</FormGroup>
            	<FormGroup>
            		<select onChange={handleChange} className="form-control" defaultValue="Means Of Identification" name="iden" required>
            			<option disabled>Means Of Identification</option>
            			<option value="International Passport">International Passport</option>
            			<option value="National ID">National ID</option>
            			<option value="Driver's Licence">Driver's Licence</option>
            			<option value="Voter's Carc">Voter's Card</option>
            		</select>
            	</FormGroup>
            	<FormGroup>
            		<Input onChange={handleChange} name="idnumber" placeholder="ID Number" />
            	</FormGroup>
            	<FormGroup>
    	        	<Row>
    	        		<Col xs="5">ID Issue Date</Col>
    	        		<Col xs="7"><Input onChange={handleChange} type="date" name="idissuedate" placeholder="ID Issue Date" /></Col>
    	        	</Row>
            	</FormGroup>
            	<FormGroup>
    	        	<Row>
    	        		<Col xs="5">ID Expiry Date</Col>
    	        		<Col xs="7"><Input onChange={handleChange} type="date" name="idexpirydate" placeholder="ID Expiry Date" /></Col>
    	        	</Row>
            	</FormGroup>
            	<FormGroup>
            		<select onChange={handleChange} className="form-control" defaultValue="Account Type" name="accounttype" required>
            			<option disabled>Account Type</option>
            			<option value="Fixed">Fixed</option>
            			<option value="Savings">Savings</option>
            		</select>
            	</FormGroup>
            </Col>
          </Row>
          <Row>
          	<h3>Uploads</h3>
            <Row>
                <Col xs="6">
                    <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        buttonText='Upload ID'
                        onChange={onIdChange}
                        imgExtension={['.jpg', '.jpeg', '.png']}
                        maxFileSize={1097152}
                        label="Max file size: 1mb, accepted: jpg|jpeg|png"
                    />
                </Col>
                <Col xs="6">
                    <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        buttonText='Upload Passport Photograph'
                        onChange={onPhotoChange}
                        imgExtension={['.jpg', '.jpeg', '.png']}
                        maxFileSize={1097152}
                        label="Max file size: 1mb, accepted: jpg|jpeg|png"
                    />
                </Col>
            </Row>
          </Row>
          <Row>
            <Button size="lg" block color="primary">Submit</Button>
          </Row>
          </Form>
    </Container>
    </div>
  );
}

export default Register;
